import { Box } from '@mui/material'
import Numeris from './features/numeris'
import { useLoginQuery } from './services/veritone'

function App() {
  const { data } = useLoginQuery({
    username: `cmoore+cs@veritone.com`,
    password: `Veritone123`,
  })

  console.log(data)

  return (
    <div>
      <Box
        sx={{
          p: 2,
          maxWidth: '1000px',
          margin: `0 auto`
        }}
        component={`main`}
      >
        <Numeris />
      </Box>
    </div>
  )
}

export default App
