import { createApi, } from '@reduxjs/toolkit/query/react'
import { request, gql, ClientError, } from 'graphql-request'

const graphqlBaseQuery = ({ baseUrl }: { baseUrl: string }) => async ({ body }: { body: string }) => {
  try {
    const token = `109a95a6-e8c6-4977-bd8f-381a620495d9`
    const result = await request(baseUrl, body, undefined, {
      authorization: token ? `Bearer ${token}` : "",
    })
    return { data: result }
  } catch (error) {
    if (error instanceof ClientError) {
      return { error: { status: error.response.status, data: error } }
    }
    return { error: { status: 500, data: error } }
  }
}

export const veritoneApi = createApi({
  reducerPath: 'veritoneApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: 'https://api.stage.us-1.veritone.com/v3/graphql',
  }),
  endpoints: (builder) => ({
    login: builder.query({
      query: ({
        username,
        password,
      }: {
        username: string,
        password: string,
      }) => ({
        body: gql`
          mutation {
            userLogin(
              input: {
                userName: "${username}",
                password: "${password}",
              }
            ) {
              token
            }
        }`,
      }),
      transformResponse: (response) => response.userLogin.token,
    }),
    getMentionsInWatchlist: builder.query({
      query: ({
        watchlistId,
        offset = 0,
        limit
      }: {
        watchlistId: string,
        offset: number,
        limit: number,
      }) => ({
        body: gql`
          query {
            mentions(watchlistId: ${watchlistId}, offset: ${offset}, limit: ${limit}) {
              count
              records {
                id,
                mentionDate,
                metadata,
                statusId
              }
            }
          }
        `,
      }),
      transformResponse: (response) => response.data,
    }),
  })
})

export const { 
  useGetMentionsInWatchlistQuery,
  useLoginQuery,
} = veritoneApi