import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {

  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
 
  }
}

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
})

export default theme