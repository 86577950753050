import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useGetMentionsInWatchlistQuery } from '../../services/veritone'

const Numeris = () => {
  const [watchlistIdValue, setWatchlistIdValue] = React.useState<string>(``)
  const [watchlistId, setWatchlistId] = React.useState<string>(``)

  const data = useGetMentionsInWatchlistQuery({
    watchlistId,
    limit: 100,
    offset: 0,
  }, {
    skip: watchlistId === ``,
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (watchlistIdValue !== ``) {
      setWatchlistId(watchlistIdValue) 
    }
  }

  console.log(data)

  return (
    <form onSubmit={handleSubmit}>
      <Typography
        component={`h1`}
        variant={`h6`}
      >Troubleshoot Numeris</Typography>
      <Box sx={{
        display: 'grid',
        gap: 2,
        paddingY: 2,
        gridTemplateColumns: '200px 300px'
      }}>
        <TextField
          label={`Watchlist ID`}
          size={`small`}
          onChange={(e) => setWatchlistIdValue(e.target.value)}
        ></TextField>
        <Button
          type={`submit`}
          variant={`contained`}
        >Troubleshoot Numeris</Button>
      </Box>
    </form>
  )
}

export default Numeris