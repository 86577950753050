import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import { veritoneApi } from '../services/veritone'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [veritoneApi.reducerPath]: veritoneApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(veritoneApi.middleware) 
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
